/**
 * Swiper
 * @path: vender/swiper/main
 */

.is-desktop{
    .c-swiper {
        swiper-container {
            &:hover {
                &::after,
                &::before {
                    opacity: 0;
                }
            }
        }
        .c-swiper__arrow-prev,
        .c-swiper__arrow-next {
            &:hover ~ swiper-container {
                &::after,
                &::before {
                    opacity: 0;
                }
            }
        }
    }
}

swiper-container::part(container) {
    padding-bottom: 2.4rem;
    overflow: visible;
}

swiper-container {
    --swiper-scrollbar-sides-offset: 1px;

    height: calc(100% - 2.4rem);
    position: relative;
    z-index: 1;

    &::after,
    &::before {
        width: 100vw;
        height: 100%;
        position: absolute;
        z-index: 10;
        top: 0;
        transition: opacity 0.3s ease-in-out;
        content: '';
    }

    &::after {
        left: 100%;
    }

    &::before {
        right: 100%;
    }

    &:hover {
        &::after,
        &::before {
            opacity: 0;
        }
    }
}

.c-swiper__arrow-next,
.c-swiper__arrow-prev {
    width: 4.8rem;
    height: 4.8rem;
    position: absolute;
    z-index: 15;
    top: 50%;
    transform: translateY(-50%);
    display: none;
    justify-content: center;
    align-items: center;
    background-color: $white;
    border: .1rem solid $black;
    border-radius: 10rem;
    cursor: pointer;
    opacity: 1;
    visibility: visible;
    transition: opacity .2s ease-in-out, visibility .2s ease-in-out;

    &.is-hidden {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }
}

.c-swiper__arrow-next {
    right: -1.2rem;
}

.c-swiper__arrow-prev {
    left: -1.2rem;
}

.c-swiper-slider {
    &.u-bg-white {
        swiper-container {
            &::after {
                background: $white;
            }

            &::before {
                background: $white;
            }
        }
    }

    swiper-container {
        &::after {
            background: $light-gray;
        }

        &::before {
            background: $light-gray;
        }
    }
}

swiper-slide {
    height: 100%;
}

@include breakpoint(md) {
    swiper-container::part(container) {
        padding-bottom: 4rem;
    }

    swiper-container {
        height: calc(100% - 4rem);
    }
}

@include breakpoint(xxl) {
    .c-swiper__arrow-next,
    .c-swiper__arrow-prev {
        display: flex;
    }
}