/**
 * Import all scss files
 */

// 1. Settings
@import '01.settings/variables';

// 2. Tools
@import '02.tools/alert';
@import '02.tools/breakpoint';
@import '02.tools/flexbox';
@import '02.tools/grid';
@import '02.tools/link';
@import '02.tools/unit';
@import '02.tools/value';
@import '02.tools/visibility';

// 3. Generic
@import '03.generic/normalize';

// 4. Elements
@import '04.elements/body';
@import '04.elements/button';
@import '04.elements/form';
@import '04.elements/headings';
@import '04.elements/link';
@import '04.elements/media';
@import '04.elements/meta';

// 5. Objects
@import '05.objects/animation';
@import '05.objects/background';
@import '05.objects/flex';
@import '05.objects/grid';
@import '05.objects/list';

// 6. Components
@import '06.components/accordion';
@import '06.components/alert';
@import '06.components/block-loader';
@import '06.components/button';
@import '06.components/card';
@import '06.components/checkbox';
@import '06.components/checkout-bar';
@import '06.components/cookie';
@import '06.components/dropdown';
@import '06.components/panel';
@import '06.components/footer';
@import '06.components/form';
@import '06.components/hamburger';
@import '06.components/header';
@import '06.components/headings';
@import '06.components/hero';
@import '06.components/icon';
@import '06.components/image';
@import '06.components/link';
@import '06.components/loader';
@import '06.components/navigation';
@import '06.components/overlay';
@import '06.components/radio';
@import '06.components/summary';
@import '06.components/switch';
@import '06.components/widget';

// 7. Utilities
@import '07.utilities/colors';
@import '07.utilities/cursor';
@import '07.utilities/dimensions';
@import '07.utilities/overflow';
@import '07.utilities/position';
@import '07.utilities/rounded';
@import '07.utilities/scroll';
@import '07.utilities/spacing';
@import '07.utilities/typography';
@import '07.utilities/visibility';

// 8. Vendors
@import '../../../node_modules/swiper/swiper';
@import '../../../node_modules/swiper/modules/navigation';
@import '../../../node_modules/swiper/modules/scrollbar';
@import '08.vendor/swiper/main';

@import '../../../node_modules/vue-select/dist/vue-select.css';
@import '08.vendor/vue-datepicker/vue-datepicker';
@import '08.vendor/vue-select/vue-select';
@import '08.vendor/agile/slider';
