/**
 * Dimensions
 * @path: utilities/dimension
 */

.u-full-width {
    width: 100%;
}

.u-max-w-xl {
    max-width: 140rem;
    margin: 0 auto;
}

.u-full-height {
    height: 100%;
}
