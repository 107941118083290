/**
 * Buttons
 * @path: components/button
 */
 .is-desktop {
    .c-button {
        &--solid {
            &:hover {
                color: $button-color-hover;
                @if $button-has-gradient {
                    background-image: $button-background-gradient-hover;
                } @else {
                    background-color: $button-background-hover;
                }
            }

            &.c-button--secondary {
                &:hover {
                    color: $button-color-hover-secondary;
                    background-color: $button-background-hover-secondary;
                }
            }

            &.c-button--success {
                &:hover {
                    color: $button-color-hover;
                    background-color: scale-color($success, $lightness: -15%);
                }
            }
        }

        &--hollow {
            &:hover {
                color: $button-hollow-color-hover;
                background-color: $button-hollow-background-hover;
                border: $button-hollow-border-hover;
            }

            &.c-button--secondary {
                &:hover {
                    color: $button-hollow-color-hover-secondary;
                    background-color: $button-hollow-background-hover-secondary;
                    border: $button-hollow-border-hover-secondary;
                }
            }

            &.c-button--white {
                &:hover {
                    color: $button-hollow-color-hover-white;
                    background-color: $button-hollow-background-hover-white;
                    border: $button-hollow-border-hover-white;
                }
            }
        }

        &--clear {
            &:hover {
                color: $button-clear-color-hover;
                background-color: $button-clear-background-hover;
                border: $button-clear-border-hover;
            }
        }

        &.disabled,
        &[disabled="true"] {
            &:hover {
                background: $button-disabled-background;
                background-color: $button-disabled-background;
                color: $button-disabled-color;
                cursor: not-allowed;
            }
        }
    }
}

.c-button {
    min-width: 4.4rem;
    height: 4.4rem;
    padding: $button-padding;
    border-radius: $button-radius;
    font-family: $button-font-family;
    font-size: map-get($button-sizes, default);
    font-weight: $button-font-weight;
    vertical-align: middle;
    line-height: 1;
    transition: $button-transition;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    column-gap: .8rem;
    white-space: nowrap;
    cursor: pointer;

    & ~ .c-button {
        margin-left: .5rem;
    }

    &--solid {
        color: $button-color;
        @if $button-has-gradient {
            background-image: $button-background-gradient;
        }
        background-color: $button-background;
        border: unset;

        &.c-button--secondary {
            color: $button-color-hover-secondary;
            background: $button-background-secondary;
            background-color: $button-background-secondary;
        }
    }

    &--hollow {
        color: $button-hollow-color;
        background-color: $button-hollow-background;
        border: $button-hollow-border;

        &.c-button--secondary {
            color: $button-hollow-color-secondary;
            border: $button-hollow-border-secondary;
        }

        &.c-button--white {
            color: $button-hollow-color-white;
            border: $button-hollow-border-white;
        }
    }

    &--clear {
        padding: 0;
        cursor: pointer;
        background-color: $button-clear-background;
        border: $button-clear-border;
        color: $button-clear-color;
        transition: $button-transition;
    }

    &.disabled,
    &[disabled="true"] {
        background: $button-disabled-background;
        background-color: $button-disabled-background;
        color: $button-disabled-color;
    }

    &--expanded {
        width: 100%;
    }

    &__icon {
        margin-left: $button-padding-icon;
        &--left {
            margin-right: $button-padding-icon;
        }
    }
}
