/**
 * Overflow
 * @path: utilities/overflow
 */

.u-overflow-hidden {
    overflow: hidden;
}

.u-overflow-x-hidden {
    overflow-x: hidden;
}