/**
 * Rounded
 * @path: utilities/rounded
 */

.u-rounded-md {
    border-radius: 1.2rem;
}

@include breakpoint(xxl) {
    .u-xl-rounded-md {
        border-radius: 1.2rem;
    }
}