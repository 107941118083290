/**
 * Colors
 * @path: utilities/colors
 * ----------------------
 *
 * Table of Contents:
 *
 * 1. Colors
*/

/**
 * Creates helper classes for colors
 *
 * @var {Map} $foundation-palette - settings/foundation
 * @output e.g. '.u-color-primary'
 */
@each $name, $value in $theme-colors {
    .u-color-#{$name} {
        color: $value !important;
    }
    .u-color-hover-#{$name} {
        &:hover {
            color: $value;
        }
    }
    .u-bg-#{$name} {
        background-color: $value !important;
    }
    .u-fill-#{$name} {
        fill: $value !important;
    }
}

.u-bg-gradient {
    background: linear-gradient(-135deg, $primary 10%, $secondary 100%);
}
